import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const DropDownSelector = ({ options, optionKey, optionValue, title, handleOnChange, isDisabled, defaultValue }) => {
  return (
    <div className="drop-down-selector">
      {/* <span className="search-titles">{title}</span> */}
      <Select
        className="supplier-selector"
        disabled={isDisabled}
        dropdownMatchSelectWidth={true}
        defaultValue={title}
        showSearch
        optionFilterProp="children"
        onChange={handleOnChange}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option key={0} disabled={true} value={null}>
          {defaultValue}
        </Option>
        {options.map(
          eachOption =>
            eachOption[optionKey] !== null && (
              <Option key={eachOption[optionKey]} value={eachOption[optionKey]} style={{ textTransform: 'uppercase' }}>
                {eachOption[optionKey] + ' - ' + eachOption[optionValue]}
              </Option>
            )
        )}
      </Select>
    </div>
  );
};

export default DropDownSelector;
