import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import _ from 'lodash';
import PlusIcon from './PlusIcon';
import { HierarchyActions } from '../../../Action/Actions';
import { tooltipConstants } from '../../../Util/constants';
import { getLocalStorageValue } from '../../../Util/util';

const EachHierarchyBox = ({
  isMainSupplier,
  name,
  taxId,
  suvc,
  duns,
  id,
  isForHierarchy,
  isDraggable,
  nodes,
  corporateSuvc,
  isSupplierList,
  isDeleted,
  meta
}) => {
  const dispatch = useDispatch();
  const isChangingCurrentHierarchy = useSelector(state => _.get(state.hierarchy, 'isChangingCurrentHierarchy', false));
  const isCreatingNewHierarchy = useSelector(state => _.get(state.hierarchy, 'isCreatingNewHierarchy', false));
  const currentDraggingSupplier = useSelector(state => _.get(state.hierarchy, 'currentDraggingSupplier', null));
  const isSubmittingChanges = useSelector(state => _.get(state.hierarchy, 'isSubmittingChanges', false));
  const supplierName = useSelector(state => _.get(state.user, 'data.supplierName', false));
  const { isChanged } = meta || {};

  const setDraggingSupplier = supplier => {
    dispatch(HierarchyActions.setCurrentDraggingSupplier(supplier));
  };

  const addDropableContainer = data => {
    dispatch(HierarchyActions.addEmptyDropableContainer(data));
  };

  const dropToEmptyContainer = data => {
    dispatch(HierarchyActions.handleDropToEmptyContainer(data));
  };

  const deleteEmptySupplierContainer = () => {
    dispatch(HierarchyActions.deleteEmptySupplier(suvc));
  };

  const getDragableStatus = () =>
    (!corporateSuvc || isDraggable) &&
    suvc &&
    !suvc.includes('empty') &&
    (isChangingCurrentHierarchy || isCreatingNewHierarchy) &&
    !isDeleted;

  const addNewEmptyElement = position => {
    if (isChangingCurrentHierarchy || isCreatingNewHierarchy)
      addDropableContainer({ childSuvc: suvc, position, corporateSuvc });
  };

  const getmiddlePlusIconClass = () => {
    let className = 'add-icon middle-add-icon ';
    if (!(isChangingCurrentHierarchy || isCreatingNewHierarchy) || isDeleted) className += 'icon-disabled ';
    if (corporateSuvc) className += ' corporate-supplier';
    if (suvc && suvc.includes('empty')) className += 'empty-name';
    return className;
  };

  const checkDisablingPlusIcon = () => {
    if (!(isChangingCurrentHierarchy || isCreatingNewHierarchy) || isDeleted || isSubmittingChanges)
      return 'icon-disabled';
    return '';
  };

  const checkDisablingForUndo = () => {
    if (!(isChangingCurrentHierarchy || isCreatingNewHierarchy) || isSubmittingChanges) return 'icon-disabled';
    return '';
  };

  const isEmptySupplier = suvc && suvc.includes('empty');

  const allowDrop = ev => {
    ev.preventDefault();
  };

  const updateDroppingHierarchicalStructurer = newStructure => {
    dispatch(HierarchyActions.updateHierarchicalStructure(newStructure));
  };

  const revertSupplierDeletion = () => {
    dispatch(HierarchyActions.discardDeletion(suvc));
  };

  const discardSupplier = () => {
    if (isEmptySupplier || isCreatingNewHierarchy) {
      deleteEmptySupplierContainer();
      return;
    }
    if (isChangingCurrentHierarchy)
      dispatch(HierarchyActions.startDeletingSupplierFromHierarchyStructure({ name, taxId, childSuvc: suvc, duns }));
  };

  const checkDraggingSuvcIsInSameHierarchy = supplierNodes => {
    let status = null;
    const check = allNodes => {
      _.forEach(allNodes, eachNode => {
        if (eachNode.childSuvc === suvc || status) {
          status = eachNode;
          return false;
        } else {
          check(eachNode.nodes);
        }
      });
      return status;
    };
    return check(supplierNodes);
  };

  const drop = () => {
    if (currentDraggingSupplier && currentDraggingSupplier.childSuvc && !currentDraggingSupplier.isDeleted) {
      if (
        currentDraggingSupplier.childSuvc !== suvc &&
        suvc.includes('empty') &&
        !checkDraggingSuvcIsInSameHierarchy(currentDraggingSupplier.nodes)
      ) {
        updateDroppingHierarchicalStructurer(currentDraggingSupplier.childSuvc);
        let newDraggaingSupplier = {
          ...currentDraggingSupplier,
          meta: { ...currentDraggingSupplier.meta, isChanged: true }
        };
        if (!newDraggaingSupplier.nodes) {
          newDraggaingSupplier = {
            ...newDraggaingSupplier,
            nodes: []
          };
        }
        dropToEmptyContainer({ dropingData: { ...newDraggaingSupplier }, replacingchildSuvc: suvc });
      }
    }
  };

  const getDeleteClassName = () => {
    if (isDeleted) return 'deleted-supplier';
    if (isChanged) return 'supplier-changed';
    return '';
  };

  const getDeleteIconStyles = () => {
    if (!(isChangingCurrentHierarchy || isCreatingNewHierarchy)) return { pointerEvents: 'none' };
    return {};
  };

  const getUndoIconStyles = () => {
    if (checkDisablingForUndo()) return { pointerEvents: 'none' };
    return {};
  };

  const getTooltipTitle = () => {
    if (corporateSuvc) return tooltipConstants['global'];
    return tooltipConstants['subsidiary'];
  };

  const generateNameWithSuvcOrCorporateLocalName = (name, suvc) => {
    if (name === '---') return suvc;
    if (name) return name;
    if (supplierName) return supplierName;
    try {
      const { name: supplierName } = JSON.parse(getLocalStorageValue('vendor'));
      return supplierName;
    } catch (error) {
      return '';
    }
  };

  const displayName = generateNameWithSuvcOrCorporateLocalName(name, suvc);

  const getLeftSupplierElementClass = () => (isSupplierList ? 'left-reduced-element' : '');

  return (
    <div
      className={`dragable-supplier-element ${getDeleteClassName()} ${
        name === 'Drop a subsidiary here' ? 'gray' : ''
      } ${getLeftSupplierElementClass()}`}
    >
      {!corporateSuvc && !isSupplierList && (
        <PlusIcon
          title="Add Supplier"
          customClass={`add-icon left-add-icon ${checkDisablingPlusIcon()}`}
          position="left"
          onAdd={addNewEmptyElement}
        />
      )}
      {isMainSupplier ? (
        <div className="text-content main-supplier-title-only">{name}</div>
      ) : (
        <>
          {!isSupplierList && (
            <div className="each-box-tooltip-container">
              <Tooltip title={<div>{getTooltipTitle()}</div>}>i</Tooltip>
            </div>
          )}
          <div
            className={`text-content ${corporateSuvc ? `corporate-supplier-text-content` : ``}`}
            draggable={getDragableStatus()}
            onDragStart={() => setDraggingSupplier({ name, taxId, duns, childSuvc: suvc, id, nodes, isDeleted })}
            onDragOver={allowDrop}
            onDrop={event => drop(event)}
          >
            <div
              id="coporate-card"
              className={corporateSuvc ? 'coporate-supplier' : 'each-text-field weighted'}
              title={displayName}
            >
              {displayName}
            </div>
            {!corporateSuvc && !isEmptySupplier && (
              <>
                <div className="each-text-field" title={suvc}>
                  suvc: <b>{suvc || '---'}</b>
                </div>
                <div className="each-text-field" title={taxId}>
                  Tax Id: <b>{taxId || '---'}</b>
                </div>
                <div className="each-text-field" title={duns}>
                  Duns #: <b>{duns || '---'}</b>
                </div>
              </>
            )}
            {isForHierarchy && (
              <PlusIcon title="Add Supplier" customClass={getmiddlePlusIconClass()} onAdd={addNewEmptyElement} />
            )}
          </div>
        </>
      )}
      {!corporateSuvc && !isSupplierList && (
        <div className={`icon-content`}>
          {/* <span>
            <EditOutlined />
          </span> */}
          {isDeleted ? (
            <UndoOutlined
              isDisabled={checkDisablingForUndo()}
              style={getUndoIconStyles()}
              title="Undo Deletion"
              onClick={() => revertSupplierDeletion()}
            />
          ) : (
            <DeleteOutlined
              title="Delete Supplier"
              style={getDeleteIconStyles()}
              onClick={() => discardSupplier()}
              isDisabled={checkDisablingPlusIcon()}
            />
          )}
        </div>
      )}
      {!corporateSuvc && !isSupplierList && (
        <PlusIcon
          title="Add Supplier"
          customClass={`add-icon right-add-icon ${checkDisablingPlusIcon()}`}
          position="right"
          onAdd={addNewEmptyElement}
        />
      )}
    </div>
  );
};

export default EachHierarchyBox;
