import _ from 'lodash';
import { seeMoreSupplierListSize } from './constants';

const updateKeyNamesInSearchResults = (data, isForSearch) => {
  return data.map(({ suvc, vendorName, taxId, dunAndBrandstreet }) => {
    return {
      name: vendorName,
      childSuvc: suvc,
      taxId,
      duns: dunAndBrandstreet,
      isForSearch
    };
  });
};

const removePreviousSearchResults = supplierList => {
  return supplierList.filter(({ isForSearch }) => !isForSearch);
};

const putNodesToList = nodes => {
  let leftSupplierList = [];
  const setSupplierList = supplierNodes => {
    supplierNodes.forEach(({ nodes, ...otherAttributes }) => {
      leftSupplierList = [...leftSupplierList, { ...otherAttributes }];
      if (nodes.length) setSupplierList(nodes);
    });
  };
  setSupplierList(nodes);
  return leftSupplierList;
};

const getTogetherAllSuppliers = (supplierHierarchy, leftSupplierList = []) => {
  if (!supplierHierarchy) return [];
  return [...putNodesToList(supplierHierarchy.nodes), ...leftSupplierList]
    .filter(({ name }) => name !== 'Drop a subsidiary here')
    .map(({ name, childSuvc }) => {
      if (name === '---') return childSuvc;
      return name;
    });
};

const addXPositionToNodes = nodes => {
  return nodes
    .map(el => (el.nodes ? { ...el, nodes: addXPositionToNodes(el.nodes) } : el))
    .map((el, index) => {
      return { ...el, xPosition: index };
    })
    .filter(({ name }) => name !== 'Drop a subsidiary here');
};

const getSupplierListDisplayCount = pageSize => seeMoreSupplierListSize * pageSize;

const checkIfMoreAvailableSuppliers = (suppliers, pageSize) => suppliers.length > getSupplierListDisplayCount(pageSize);

const getLocalStorageValue = key => localStorage.getItem(key);

const checkIfSuvcExistsInNodes = (supplierNodes, suvc) => {
  let status = null;
  const check = allNodes => {
    _.forEach(allNodes, eachNode => {
      if (eachNode.childSuvc === suvc || status) {
        status = eachNode;
        return false;
      } else {
        check(eachNode.nodes, suvc);
      }
    });
    return status;
  };
  return check(supplierNodes);
};

const removeExistingSuppliersFromLeftList = (leftList, hierarchyStructure) => {
  return leftList.filter(({ childSuvc, ...rest }) => {
    if (
      !checkIfSuvcExistsInNodes(hierarchyStructure.nodes, childSuvc) &&
      hierarchyStructure.corporateSuvc !== childSuvc
    ) {
      return { ...rest, childSuvc };
    }
  });
};

const checkUserHasEditPermissions = permissions => permissions.includes('HIERARCHY.EDIT.ALL');

const checkSearchingSupplierPermissions = permissions => permissions.includes('HIERARCHY.SEARCH.SUPPLIERS');

const getUniqueSuppliers = supplierSet => {
  return _.uniqBy(supplierSet, 'childSuvc');
};

export {
  updateKeyNamesInSearchResults,
  putNodesToList,
  getTogetherAllSuppliers,
  addXPositionToNodes,
  getSupplierListDisplayCount,
  checkIfMoreAvailableSuppliers,
  getLocalStorageValue,
  removeExistingSuppliersFromLeftList,
  checkUserHasEditPermissions,
  checkSearchingSupplierPermissions,
  getUniqueSuppliers,
  removePreviousSearchResults
};
