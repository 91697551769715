import React from 'react';
import { Input } from 'antd';

const SearchSelector = ({ fieldName, placeholder, searchedValue, handleTextChange, isDisabled }) => {
  return (
    <div className="drop-down-selector">
      <Input
        className="search-box"
        placeholder={placeholder}
        enterButton
        id="id-search-box"
        value={searchedValue}
        onChange={event => handleTextChange(fieldName, event.target.value)}
        disabled={isDisabled}
      />
    </div>
  );
};

export default SearchSelector;
