import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import _ from 'lodash';
import { HierarchyActions } from '../../../Action/Actions';

const Footer = () => {
  const isChangingCurrentHierarchy = useSelector(state => _.get(state.hierarchy, 'isChangingCurrentHierarchy', false));
  const isCreatingNewHierarchy = useSelector(state => _.get(state.hierarchy, 'isCreatingNewHierarchy', false));
  const hierarchyStructure = useSelector(state => _.get(state.hierarchy, 'hierarchyStructure', {}));
  const isSubmittingChanges = useSelector(state => _.get(state.hierarchy, 'isSubmittingChanges', false));
  const dispatch = useDispatch();

  const submitChanges = () => {
    dispatch(HierarchyActions.submitHierarchyChanges(hierarchyStructure));
  };

  const getButtonDisableStatus = () => !(isChangingCurrentHierarchy || isCreatingNewHierarchy) || isSubmittingChanges;
  const getButtonDisableClass = () => (getButtonDisableStatus() ? 'disabled-submit-button' : '');
  const getButtonTitle = () => (isSubmittingChanges ? 'Submitting...' : 'Save Changes');
  return (
    <div className="footer">
      <Button
        className={`active-buttons ${getButtonDisableClass()}`}
        disabled={getButtonDisableStatus()}
        onClick={() => submitChanges()}
        type="primary"
      >
        {getButtonTitle()}
      </Button>
    </div>
  );
};

export default Footer;
