import React from 'react';

const HeaderTab = () => {
  return (
    <div className="grid-filter-panel" style={{}}>
      <React.Fragment>
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        ></div>
        <div className="bread-crum-seperator"></div>
        <div className="bread-crum-caption">Hierarchy Management</div>
      </React.Fragment>
    </div>
  );
};

export default HeaderTab;
