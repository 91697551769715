import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import HierarchyRoutes from './Routes';

function App(props) {
  const {
    Auth: {
      user: { username }
    }
  } = props;

  return (
    <Provider store={store}>
      <HierarchyRoutes username={username} />
    </Provider>
  );
}

export default App;
