import { takeLatest } from 'redux-saga/effects';
import { HierarchyActionTypes } from '../../Action/ActionTypes';
import {
  searchSuppliersAsync,
  getInitialSupplierAsync,
  submitSupplierHierarchyChangesAsync,
  getSupplierListAsync
} from './HierarchySaga';

const HierarchySaga = [
  takeLatest(HierarchyActionTypes.SEARCH_SUPPLIERS.REQUEST, searchSuppliersAsync),
  takeLatest(HierarchyActionTypes.LOAD_INITIAL_SUPPLIER.REQUEST, getInitialSupplierAsync),
  takeLatest(HierarchyActionTypes.SUBMIT_CHANGES.REQUEST, submitSupplierHierarchyChangesAsync),
  takeLatest(HierarchyActionTypes.GET_VENDORS.REQUEST, getSupplierListAsync)
];

export default HierarchySaga;
