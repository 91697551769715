import React from 'react';
import HierarchyStructureTitle from '../SubComponents/HierarchyStructureTitle';
import HierarchyStructure from '../SubComponents/HierarchyStructure';
import HierarchyList from '../SubComponents/HierarchyList';

const HierarchyContainer = () => {
  return (
    <div className="hierarchy-container">
      <HierarchyList />
      <div className="right-hiearchy-container">
        <HierarchyStructureTitle />
        <HierarchyStructure />
      </div>
    </div>
  );
};

export default HierarchyContainer;
