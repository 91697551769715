import initialState from '../store/initialState';

import { UserActionTypes as USER } from '../Action/ActionTypes';

import { createReducer } from '../reduxHelpers';

const user = (state = initialState.user, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER.USER.REQUEST: {
      return {
        ...state,
        fetching: true
      };
    }
    case USER.USER.SUCCESS: {
      return {
        ...state,
        data: {
          ...payload
        },
        fetching: false
      };
    }
    case USER.USER.FAILURE: {
      return {
        ...state,
        fetching: false
      };
    }
    case USER.SET_SUPPLIER_SUVC.REQUEST: {
      const { suvc, supplierName } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          suvc,
          supplierName
        }
      };
    }
    default:
      return state;
  }
};

createReducer(USER, initialState.user);

export default user;
