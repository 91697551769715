import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { HierarchyActions } from '../../../Action/Actions';

const CancelDeletionRequest = () => {
  const dispatch = useDispatch();
  const currentDeletingSupplier = useSelector(state => _.get(state.hierarchy, 'currentDeletingSupplier', {}));
  const isDeleteRequestSubmitted = useSelector(state => _.get(state.hierarchy, 'isDeleteRequestSubmitted', false));

  const cancelSupplierDeletion = suvc => {
    dispatch(HierarchyActions.discardDeletion(suvc));
    dispatch(HierarchyActions.supplierDeleteReject());
  };

  const updateModalFieldStatus = () => {
    dispatch(
      HierarchyActions.updateFields({
        isDeleteRequestSubmitted: !isDeleteRequestSubmitted,
        currentDeletingSupplier: {}
      })
    );
  };

  const getDisplayName = () => {
    if (currentDeletingSupplier) {
      const { childSuvc, name } = currentDeletingSupplier;
      if (name === '---') return childSuvc;
      return name;
    }
    return 'supplier';
  };

  return (
    <Modal
      visible={isDeleteRequestSubmitted}
      footer={null}
      centered
      closeIcon={<CloseOutlined style={{ color: '#FF0000' }} />}
      onCancel={() => updateModalFieldStatus()}
    >
      <div style={{ display: 'grid', marginTop: '5%' }}>
        <div style={{ textAlign: 'center' }}>
          <p>
            You are deleting the subsidiary <b>{getDisplayName()}</b> from your hierarchy.
          </p>
          <p>This request is being reviewed. Please allow up to 24h to see this change reflected in your hierarchy</p>
        </div>

        <div style={{ display: 'grid' }}>
          <span style={{ color: 'red' }}>
            Deletion wont be reflected unless clicking on <b>Save Changes</b> button on the screen below
          </span>
        </div>
        <div>
          <Button
            onClick={() => {
              updateModalFieldStatus();
            }}
            title="Submit review will reflect the deletion  after clicking on Save Changes button below"
            style={{
              float: 'right',
              backgroundColor: 'rgb(71, 149, 224)',
              color: 'white',
              fontWeight: '600',
              borderRadius: '3px',
              height: '25px'
            }}
          >
            Submit Review
          </Button>
          <Button
            onClick={() => {
              cancelSupplierDeletion(currentDeletingSupplier.childSuvc);
            }}
            title="Cancel Deletion"
            style={{
              float: 'right',
              color: '#838383',
              backgroundColor: 'white',
              fontWeight: '600',
              borderRadius: '3px',
              height: '25px'
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelDeletionRequest;
