import { createRequestTypes } from './../../reduxHelpers';

const HIERARCHY = createRequestTypes('HIERARCHY');
const ADD_SEARCH_HIERARCHY = createRequestTypes('ADD_SEARCH_HIERARCHY');
const SET_DRAGGING_SUPPLIER = createRequestTypes('SET_DRAGGING_SUPPLIER');
const SET_UPDATE_HIERARCHICAL_STRUCTURE = createRequestTypes('SET_UPDATE_HIERARCHICAL_STRUCTURE');
const ADD_EMPTY_SINGLE_HIERARCHY_CONTAINER = createRequestTypes('ADD_EMPTY_SINGLE_HIERARCHY_CONTAINER');
const SEARCH_SUPPLIERS = createRequestTypes('SEARCH_SUPPLIERS');
const CHANGE_HIERARCHY_BUTTON_SELECT = createRequestTypes('CHANGE_HIERARCHY_BUTTON_SELECT');
const DROP_TO_EMPTY_SUPPLIER_CONTAINER = createRequestTypes('DROP_TO_EMPTY_SUPPLIER_CONTAINER');
const LOAD_INITIAL_SUPPLIER = createRequestTypes('LOAD_INITIAL_SUPPLIER');
const DELETE_EXISTING_SUPPLIER = createRequestTypes('DELETE_EXISTING_SUPPLIER');
const DELETE_EMPTY_SUPPLIER = createRequestTypes('DELETE_EMPTY_SUPPLIER');
const DISCARD_SUPPLIER_DELETION = createRequestTypes('DISCARD_SUPPLIER_DELETION');
const SUBMIT_CHANGES = createRequestTypes('SUBMIT_CHANGES');
const ALTER_NAME_LIST_DISPLAY = createRequestTypes('ALTER_NAME_LIST_DISPLAY');
const SEE_MORE_PAGE_CHANGE = createRequestTypes('SEE_MORE_PAGE_CHANGE');
const UPDATE_FIELDS = createRequestTypes('UPDATE_FIELDS');
const GET_VENDORS = createRequestTypes('GET_VENDORS');

export {
  HIERARCHY,
  ADD_SEARCH_HIERARCHY,
  SET_DRAGGING_SUPPLIER,
  SET_UPDATE_HIERARCHICAL_STRUCTURE,
  ADD_EMPTY_SINGLE_HIERARCHY_CONTAINER,
  SEARCH_SUPPLIERS,
  CHANGE_HIERARCHY_BUTTON_SELECT,
  DROP_TO_EMPTY_SUPPLIER_CONTAINER,
  LOAD_INITIAL_SUPPLIER,
  DELETE_EXISTING_SUPPLIER,
  DELETE_EMPTY_SUPPLIER,
  DISCARD_SUPPLIER_DELETION,
  SUBMIT_CHANGES,
  ALTER_NAME_LIST_DISPLAY,
  SEE_MORE_PAGE_CHANGE,
  UPDATE_FIELDS,
  GET_VENDORS
};
