const hierarchy = {
  isFetchingHierarchy: false,
  hierarchyStructure: null,
  leftSupplierList: [],
  currentDraggingSupplier: null,
  isLoadingSearchResults: false,
  isChangingCurrentHierarchy: false,
  isCreatingNewHierarchy: false,
  currentStructure: null,
  currentDeletingSupplier: {},
  isSubmittingChanges: false,
  isExpandedEnabledForNameList: false,
  currentSeeMorePageNumber: 1,
  isDeleteRequestSubmitted: false,
  vendors: []
};

export default hierarchy;
