import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import TopSearchContainer from '../Containers/TopSearchContainer';
import HierarchyContainer from '../Containers/HierarchyContainer';
import Footer from '../SubComponents/Footer';
import { HierarchyActions } from '../../Action/Actions';

const Hierarchy = () => {
  const dispatch = useDispatch();
  const suvc = useSelector(state => _.get(state.user, 'data.suvc', null));

  const loadSupplierData = useCallback(
    suvc => {
      dispatch(HierarchyActions.loadInitialSupplierData({ suvc }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (suvc) {
      loadSupplierData(suvc);
    }
  }, [loadSupplierData, suvc]);

  return (
    <React.Fragment>
      <TopSearchContainer />
      <HierarchyContainer />
      <Footer />
    </React.Fragment>
  );
};

export default Hierarchy;
