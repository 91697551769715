import React from 'react';
import { Button } from 'antd';

const ButtonSelector = ({ title, type = 'primary', handleSubmit, isDisabled, className }) => {
  return (
    <Button
      className={`add-hierarchy-button ${className}`}
      disabled={isDisabled}
      type={type}
      onClick={() => handleSubmit()}
    >
      {title}
    </Button>
  );
};

export default ButtonSelector;
