import React from 'react';
import { PlusSquareTwoTone } from '@ant-design/icons';

const PlusIcon = ({ customClass, position = 'middle', onAdd, title }) => (
  <PlusSquareTwoTone
    className={customClass}
    twoToneColor="#389e0d"
    onClick={() => {
      onAdd(position);
    }}
    title={title}
  />
);

export default PlusIcon;
