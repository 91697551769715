import React from 'react';
import EachHierarchyBox from '../EachHierarchyBox';

const EachHierarchyLevel = ({
  eachLevel: { id, childSuvc, nodes, corporateSuvc, taxId, duns, name, isDeleted, meta },
  nodesLength,
  level,
  isFirstElement,
  isLastElement
}) => {
  const { isChanged } = meta || {};
  let draggableContainerClass = '';
  let nodeContainerClass = 'node-container';
  let customNodeContainerClass = 'custom-node-container';
  if (nodesLength > 1) {
    draggableContainerClass = 'dragable-supplier-element-container';
  }
  if (nodes.length > 1) {
    nodeContainerClass += ` border-dark`;
  }

  if (isFirstElement) {
    customNodeContainerClass += ' first-element';
  } else if (isLastElement) {
    customNodeContainerClass += ' last-element';
  } else {
    customNodeContainerClass += ' rest-element';
  }

  const getIsForHierarchy = () => {
    if (nodes.length === 0) return true;
    // if (nodes.length === 1 && nodes[0].isDeleted) return true; // enable this when hiding the deleted nodes
  };

  const getDeletedClassName = () => {
    if (isDeleted) return 'deleted-supplier';
    if (isChanged) return 'supplier-changed';
    return '';
  };

  return childSuvc || corporateSuvc ? (
    <div>
      {!corporateSuvc && <div className={customNodeContainerClass}></div>}
      <div className={draggableContainerClass}>
        <div
          className={`dragable-supplier-element supplier-element ${getDeletedClassName()} ${
            name === 'Drop a subsidiary here' ? 'gray' : ''
          }`}
        >
          <EachHierarchyBox
            suvc={childSuvc}
            duns={duns}
            taxId={taxId}
            name={name}
            id={id}
            isForHierarchy={getIsForHierarchy()}
            isFirstElement={isFirstElement}
            isLastElement={isLastElement}
            nodes={nodes}
            corporateSuvc={corporateSuvc}
            isDeleted={isDeleted}
            meta={meta}
          />
        </div>
        {nodes.length > 1 && <div className="empty-div-container"></div>}
        <div className={nodeContainerClass}>
          {nodes.map((eachIteration, index) => {
            return (
              <EachHierarchyLevel
                key={index}
                eachLevel={eachIteration}
                nodesLength={nodes.length}
                level={level + 1}
                isFirstElement={index === 0}
                isLastElement={nodes.length - 1 === index}
              />
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default EachHierarchyLevel;
