import { UserActionTypes } from '../ActionTypes';

const getUserDetails = data => {
  return {
    type: UserActionTypes.USER.REQUEST,
    payload: data
  };
};

const setSupplier = payload => {
  return {
    type: UserActionTypes.SET_SUPPLIER_SUVC.REQUEST,
    payload
  };
};

export { getUserDetails, setSupplier };
