import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { Spin } from 'antd';
import EachHierarchyLevel from './EachHierarchyLevel';
import { HierarchyActions } from '../../../Action/Actions';
import DeleteModal from '../HierarchyDeletion';
import CancelSupplierDeletion from '../HierarchyDeletion/CancelDeletionRequest';
import { checkUserHasEditPermissions } from '../../../Util/util';

const changeHierarchyButton = 'isChangingCurrentHierarchy';
const createHierarchyButton = 'isCreatingNewHierarchy';

const HierarchyStructure = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const hierarchyStructure = useSelector(state => _.get(state.hierarchy, 'hierarchyStructure', {}));
  const isChangingCurrentHierarchy = useSelector(state => _.get(state.hierarchy, changeHierarchyButton, false));
  const isCreatingNewHierarchy = useSelector(state => _.get(state.hierarchy, createHierarchyButton, false));
  const permissions = useSelector(state => _.get(state.user, 'data.permissions', []));
  const isLoadingCurrentHierarchy = useSelector(state => _.get(state.hierarchy, 'isFetchingHierarchy', false));
  const [isAlreadyScrolled, setScrolledStatus] = useState(false);

  const hasEditPermissions = checkUserHasEditPermissions(permissions);

  const scrollToDivRef = () => {
    let node = ReactDOM.findDOMNode(inputRef.current);
    if (node) {
      node.scrollIntoView({ block: 'nearest', inline: 'center' });
    }
  };

  useEffect(() => {
    if (!isAlreadyScrolled && hierarchyStructure && hierarchyStructure.nodes && hierarchyStructure.nodes.length > 0) {
      setScrolledStatus(true);
      scrollToDivRef();
    }
  }, [hierarchyStructure]);

  const changeButtonAction = data => {
    dispatch(HierarchyActions.handleHierarchyChangeButtonsClick(data));
  };

  const handleButtonClick = field => {
    if (field === changeHierarchyButton)
      return changeButtonAction({ isCreatingNewHierarchy: false, isChangingCurrentHierarchy: true });
    if (field === createHierarchyButton)
      return changeButtonAction({ isCreatingNewHierarchy: true, isChangingCurrentHierarchy: false });
  };

  const getDisabledClassName = () => {
    if (isCreatingNewHierarchy || isChangingCurrentHierarchy || !hasEditPermissions) return 'disabled-hierarchy-button';
    return '';
  };

  const getButtonTitle = (field, condition) => {
    if (field === createHierarchyButton && condition && condition === isChangingCurrentHierarchy)
      return 'Currently changing the hierarchy, please refresh the page if you still need to create a new hierarchy';
    if (field === changeHierarchyButton && condition && condition === isCreatingNewHierarchy)
      return 'Currently creating a new hierarchy, please refresh the page if you still need to update the existing hierarchy';
  };

  if (hierarchyStructure && !isLoadingCurrentHierarchy) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div className="hiearchy-top-button-container">
          <Button
            type="primary"
            className={`active-buttons ${getDisabledClassName()}`}
            onClick={() => handleButtonClick(changeHierarchyButton)}
            disabled={isChangingCurrentHierarchy || isCreatingNewHierarchy || !hasEditPermissions}
            title={getButtonTitle(changeHierarchyButton, isCreatingNewHierarchy)}
          >
            Change Current Hierarchy
          </Button>
          <Button
            type="primary"
            className={`active-buttons ${getDisabledClassName()}`}
            onClick={() => handleButtonClick(createHierarchyButton)}
            disabled={isCreatingNewHierarchy || isChangingCurrentHierarchy || !hasEditPermissions}
            title={getButtonTitle(createHierarchyButton, isChangingCurrentHierarchy)}
          >
            Create New Hierarchy
          </Button>
        </div>
        <div className="hierarchy-structure-title-container">
          <span className="supplier-list-container">Please complete your organizational hierarchy below</span>
        </div>
        <div className="organization-structure">
          <div ref={inputRef} className="hierarchy-level-seperator">
            <EachHierarchyLevel eachLevel={hierarchyStructure} level={1} />
          </div>
          <DeleteModal />
          <CancelSupplierDeletion />
        </div>
      </div>
    );
  }
  if (isLoadingCurrentHierarchy) {
    return (
      <div className="centered-spin-info">
        <Spin tip="Loading your hierarchy"></Spin>
      </div>
    );
  } else if (permissions.includes('HIERARCHY.SEARCH.SUPPLIERS')) {
    return (
      <div className="centered-spin-info">
        <span className="warning-message">
          Please select desired supplier from the <b>Global Company</b> drop-down to display hierarchy
        </span>
      </div>
    );
  }
  return null;
};

export default HierarchyStructure;
