import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import SearchSelector from '../FieldComponents/SearchSelector';
import ButtonSelector from '../FieldComponents/ButtonSelector';
import DropDownSelector from '../FieldComponents/DropDownSelector';
import { HierarchyActions, UserActions } from '../../Action/Actions';
import { checkUserHasEditPermissions, checkSearchingSupplierPermissions } from '../../Util/util';

const TopSearchContainer = () => {
  const dispatch = useDispatch();
  const [tableFilter, setTableFilter] = useState([]);
  const isLoadingSearchResults = useSelector(state => _.get(state.hierarchy, 'isLoadingSearchResults', false));
  const hierarchyStructure = useSelector(state => _.get(state.hierarchy, 'hierarchyStructure', {}));
  const permissions = useSelector(state => _.get(state.user, 'data.permissions', []));
  const vendors = useSelector(state => _.get(state.hierarchy, 'vendors', []));
  const leftSupplierList = useSelector(state => _.get(state.hierarchy, 'leftSupplierList', []));

  const hasEditPermissions = checkUserHasEditPermissions(permissions);
  const hasSearchPermissions = checkSearchingSupplierPermissions(permissions);

  const freeSearchSuppliers = useCallback(() => {
    dispatch(HierarchyActions.searchSuppliers({ tableFilter, hierarchyStructure, leftSupplierList }));
  }, [tableFilter, hierarchyStructure, leftSupplierList, dispatch]);

  const fetchSuppliers = useCallback(() => {
    dispatch(HierarchyActions.getSuppliersList());
  }, [dispatch]);

  const setSelectedSupplier = suvc => {
    const [{ supplierName }] = vendors.filter(eachvendor => eachvendor.suvc === suvc);
    dispatch(UserActions.setSupplier({ suvc, supplierName }));
  };

  useEffect(() => {
    if (checkSearchingSupplierPermissions(permissions)) {
      fetchSuppliers();
    }
  }, [permissions, fetchSuppliers]);

  const getOperator = key => {
    if (key === 'vendorName') return 'like';
    return '=';
  };

  const getExistingTableFilter = param => _.find(tableFilter, { param });

  const setSearchedValues = (key, value) => {
    const existingFilterForKey = getExistingTableFilter(key);
    if (!existingFilterForKey) {
      setTableFilter([
        ...tableFilter,
        {
          param: key,
          operator: getOperator(key),
          val: [value]
        }
      ]);
    } else {
      let existingFilter = [...tableFilter];
      if (value === '') {
        existingFilter = existingFilter.filter(({ param }) => param !== key);
      } else {
        _.find(existingFilter, { param: key }).val = [value];
      }
      setTableFilter(existingFilter);
    }
  };

  const getSearchedValue = key => {
    const existingFilterForKey = getExistingTableFilter(key);
    if (existingFilterForKey && existingFilterForKey.val) return existingFilterForKey.val[0];
    return null;
  };

  const subsidiaryValue = getSearchedValue('vendorName');
  const suvcValue = getSearchedValue('suvc');
  const taxIdValue = getSearchedValue('taxId');
  const dunsNoValue = getSearchedValue('dunAndBrandstreet');

  const submitButtonTitle = isLoadingSearchResults ? 'Searching...' : 'Search';

  const checkIsButtonDisabled = () => !tableFilter.length || isLoadingSearchResults || !hasEditPermissions;

  const getDefaultTextForDropDownSelector = () => (!vendors.length ? 'Loading...' : 'Global Company');

  return (
    <div className="left-search-panel">
      {hasSearchPermissions && (
        <div>
          <DropDownSelector
            title="Global Company"
            defaultValue={getDefaultTextForDropDownSelector()}
            options={vendors}
            optionKey="suvc"
            optionValue="supplierName"
            handleOnChange={setSelectedSupplier}
          />
        </div>
      )}
      <div>
        <SearchSelector
          title="Manufacturer/ Subsidiary"
          placeholder="Search Manufacturer/Subsidiary"
          fieldName="vendorName"
          handleTextChange={setSearchedValues}
          searchedValue={subsidiaryValue}
          isDisabled={!hasEditPermissions}
        />
      </div>
      <div>
        <SearchSelector
          title="SUVC"
          placeholder="Search SUVC"
          fieldName="suvc"
          handleTextChange={setSearchedValues}
          searchedValue={suvcValue}
          isDisabled={!hasEditPermissions}
        />
      </div>
      <div>
        <SearchSelector
          title="Tax ID"
          placeholder="Search Tax ID"
          fieldName="taxId"
          handleTextChange={setSearchedValues}
          searchedValue={taxIdValue}
          isDisabled={!hasEditPermissions}
        />
      </div>
      <div>
        <SearchSelector
          title="Duns NO"
          placeholder="Search Duns No"
          fieldName="dunAndBrandstreet"
          handleTextChange={setSearchedValues}
          searchedValue={dunsNoValue}
          isDisabled={!hasEditPermissions}
        />
      </div>
      {/* <div>
            <Switcher title="Logically Active SUS" />
          </div> */}
      <ButtonSelector
        type="primary"
        className={checkIsButtonDisabled() ? 'disabled-add-to-supplier' : ''}
        isDisabled={checkIsButtonDisabled()}
        title={submitButtonTitle}
        handleSubmit={() => freeSearchSuppliers()}
      />
    </div>
  );
};

export default TopSearchContainer;
