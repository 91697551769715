import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import EachHierarchyBox from './EachHierarchyBox';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { HierarchyActions } from '../../Action/Actions';
import {
  getTogetherAllSuppliers,
  checkIfMoreAvailableSuppliers,
  getSupplierListDisplayCount,
  getLocalStorageValue
} from '../../Util/util';

const HierarchyList = () => {
  const dispatch = useDispatch();
  const leftSupplierList = useSelector(state => _.get(state.hierarchy, 'leftSupplierList', []));
  const currentDraggingSupplier = useSelector(state => _.get(state.hierarchy, 'currentDraggingSupplier', null));
  const supplierHierarchy = useSelector(state => _.get(state.hierarchy, 'hierarchyStructure', {}));
  const isNameListExpanded = useSelector(state => _.get(state.hierarchy, 'isExpandedEnabledForNameList', false));
  const currentSeeMorePageNo = useSelector(state => _.get(state.hierarchy, 'currentSeeMorePageNumber', 1));

  const addSupplierToList = supplier => {
    dispatch(HierarchyActions.addSearchHierachy(supplier));
  };

  const handleSeeMore = () => {
    dispatch(HierarchyActions.seeMoreSuppliers());
  };

  const updateDroppingHierarchicalStructurer = newStructure => {
    dispatch(HierarchyActions.updateHierarchicalStructure(newStructure));
  };

  const toggleDisplayHierarchyNames = () => {
    dispatch(HierarchyActions.alterHierarchyNameListDisplay({ isExpandedEnabledForNameList: !isNameListExpanded }));
  };

  const allowDrop = ev => {
    ev.preventDefault();
  };

  const getMainSupplierName = key => {
    if (supplierHierarchy && supplierHierarchy[key]) return supplierHierarchy[key];
    try {
      const { name } = JSON.parse(getLocalStorageValue('vendor'));
      return name;
    } catch (error) {}
    return '';
  };

  const drop = () => {
    if (
      currentDraggingSupplier &&
      currentDraggingSupplier.childSuvc &&
      !currentDraggingSupplier.childSuvc.includes('empty-')
    ) {
      const existingSupplier = _.find(leftSupplierList, { childSuvc: currentDraggingSupplier.childSuvc }, null);
      if (!existingSupplier) {
        updateDroppingHierarchicalStructurer(currentDraggingSupplier.childSuvc);
        addSupplierToList(currentDraggingSupplier);
      }
    }
  };

  const subsidiaryNamesList = getTogetherAllSuppliers(supplierHierarchy);

  const globalSupplierName = getMainSupplierName('name').toUpperCase();

  const getCustomWidthForLeftList = () => {
    if (leftSupplierList && leftSupplierList.length <= 6) return 'width-minimum';
    return '';
  };

  return (
    <div className="left-hiearchy-container" onDragOver={allowDrop} onDrop={event => drop(event)}>
      <div className="content-container">
        <div>
          {isNameListExpanded ? (
            <MinusSquareOutlined title="Contract content" onClick={() => toggleDisplayHierarchyNames()} />
          ) : (
            <PlusSquareOutlined title="Expand content" onClick={() => toggleDisplayHierarchyNames()} />
          )}
          <span className="main-supplier-title" title={globalSupplierName}>
            {globalSupplierName}
          </span>
        </div>
        {isNameListExpanded && (
          <div className="main-supplier-details">
            {subsidiaryNamesList.map((name, index) => (
              <li className="each-supplier-names" title={name} key={index}>
                {name}
              </li>
            ))}
          </div>
        )}
      </div>
      <div className="dragable-container">
        <div style={{ display: 'none' }}>
          <span className="section-title">Supplier Global Parent</span>
          <EachHierarchyBox name={getMainSupplierName('name')} isMainSupplier={true} isSupplierList={true} />
        </div>
        <div className="subsidiary-left-container">
          <span className="section-title">Subsidiary Search Results</span>
          {leftSupplierList && leftSupplierList.length > 0 && (
            <div className="supplier-list-container">You can drag and drop your subsidiaries into your hierarchy</div>
          )}
          {leftSupplierList.length > 0 ? (
            <div className={`left-supplier-box-container-only ${getCustomWidthForLeftList()}`}>
              {leftSupplierList
                .slice(0, getSupplierListDisplayCount(currentSeeMorePageNo))
                .map(({ name, taxId, duns, childSuvc }) => {
                  return (
                    <EachHierarchyBox
                      key={childSuvc}
                      name={name}
                      suvc={childSuvc}
                      taxId={taxId}
                      duns={duns}
                      isDraggable={true}
                      isSupplierList={true}
                    />
                  );
                })}
            </div>
          ) : (
            <div style={{ fontSize: '12px' }}>...</div>
          )}
        </div>
        {checkIfMoreAvailableSuppliers(leftSupplierList, currentSeeMorePageNo) && (
          <div className="see-more-container">
            <span onClick={() => handleSeeMore()} className="see-more-text" title="Load more suppliers">
              See More...
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HierarchyList;
