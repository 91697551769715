import { put } from 'redux-saga/effects';
import { getRequest } from '../../_http';
import { UserActionTypes, HierarchyActionTypes } from '../../Action/ActionTypes';

import { includes, split } from 'lodash';
import { action } from '../../reduxHelpers';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (includes(username, adPrefix)) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  var oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadUserAsync({ payload: { username } }) {
  try {
    const response = yield getRequest(`/user/${getUserId(username)}?app_name=HIERARCHY`);
    yield put({ type: UserActionTypes.USER.SUCCESS, payload: response.data });
    // yield put({ type: HierarchyActionTypes.LOAD_INITIAL_SUPPLIER.REQUEST, payload: { suvc: response.data.suvc } });
  } catch (error) {
    yield put({ type: UserActionTypes.USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }
}

export { loadUserAsync };
