import initialState from '../store/initialState';
import _ from 'lodash';
import { HierarchyActionTypes as HIERARCHY } from '../Action/ActionTypes';
import { createReducer } from '../reduxHelpers';
import { putNodesToList } from '../Util/util';

const handleRemoval = (nodes, key) => {
  return nodes
    .map(el => (el.nodes ? { ...el, nodes: handleRemoval(el.nodes, key) } : el))
    .filter(el => el.childSuvc !== key);
};

const handleDropingSuppliersToEmptyContainer = (nodes, key, dropingSupplierDetails, masterSuvc) => {
  return nodes
    .map(el =>
      el.nodes
        ? {
            ...el,
            masterSuvc,
            nodes: handleDropingSuppliersToEmptyContainer(
              el.nodes,
              key,
              dropingSupplierDetails,
              el.corporateSuvc || el.childSuvc
            )
          }
        : { ...el, masterSuvc }
    )
    .map(el => {
      if (el.childSuvc === key) {
        return {
          ...dropingSupplierDetails,
          masterSuvc: el.masterSuvc,
          nodes: [...dropingSupplierDetails.nodes, ...el.nodes]
        };
      }
      return el;
    });
};

const createEmptySupplier = () => {
  const generatedId = `empty-${(Math.random() + 1).toString(36).substring(6)}`;
  return {
    nodes: [],
    childSuvc: generatedId,
    name: 'Drop a subsidiary here'
  };
};

const handleAddingEmptyContainer = (nodes, position, childSuvc, hierarchyStructure = {}) => {
  let existingIndex = _.findIndex(nodes, node => node.childSuvc === childSuvc);
  if (existingIndex > -1) {
    const emptySupplier = createEmptySupplier();
    if (['left', 'right'].includes(position)) {
      const masterSuvc = nodes[existingIndex].masterSuvc;
      if (position === 'right') existingIndex++;
      const updatedNodes = [
        ...nodes.slice(0, existingIndex),
        { ...emptySupplier, masterSuvc },
        ...nodes.slice(existingIndex)
      ];
      return updatedNodes;
    }
    if (position === 'middle') {
      return [
        ...nodes.slice(0, existingIndex),
        {
          ...nodes[existingIndex],
          nodes: [{ ...emptySupplier, masterSuvc: nodes[existingIndex].childSuvc }]
        },
        ...nodes.slice(existingIndex + 1, nodes.length)
      ];
    }
  }
  return nodes.map(el => (el.nodes ? { ...el, nodes: handleAddingEmptyContainer(el.nodes, position, childSuvc) } : el));
};

const putAllNodesToAList = ({ nodes, ...rest } = { nodes: [] }, currentSupplierHierarchy) => {
  let leftSupplierList = [];
  const hierarchyStructure = { ...rest, nodes: [] };
  if (!nodes) return { leftSupplierList, hierarchyStructure };
  if (currentSupplierHierarchy && currentSupplierHierarchy.nodes)
    leftSupplierList = putNodesToList(currentSupplierHierarchy.nodes);
  return { leftSupplierList, hierarchyStructure };
};

const deleteExistingSubsidiary = (nodes, updatingData, childSuvc) => {
  return nodes
    .map(el => (el.nodes ? { ...el, nodes: deleteExistingSubsidiary(el.nodes, updatingData, childSuvc) } : el))
    .map(({ feedback = [], ...rest }) => {
      let dataObject = { ...rest };
      if (feedback.length) dataObject = { ...dataObject, feedback };
      if (rest.childSuvc === childSuvc) {
        return {
          ...dataObject,
          isDeleted: true,
          feedback: [...feedback, { ...updatingData }]
        };
      }
      return dataObject;
    });
};

const discardSupplierDeletion = (nodes, childSuvc) => {
  return nodes
    .map(el => (el.nodes ? { ...el, nodes: discardSupplierDeletion(el.nodes, childSuvc) } : el))
    .map(({ isDeleted, feedbacks, ...rest }) => {
      if (rest.childSuvc === childSuvc) {
        let updatedFeedbacks = [];
        if (feedbacks) {
          updatedFeedbacks = [...feedbacks.slice(0, -1)];
        }
        return {
          ...rest,
          feedback: [...updatedFeedbacks]
        };
      }
      return {
        ...rest,
        isDeleted,
        feedbacks
      };
    });
};

const hierarchy = (state = initialState.hierarchy, action) => {
  const { type, payload } = action;
  switch (type) {
    case HIERARCHY.ADD_SEARCH_HIERARCHY.REQUEST: {
      return {
        ...state,
        leftSupplierList: [...state.leftSupplierList, payload]
      };
    }
    case HIERARCHY.SET_DRAGGING_SUPPLIER.REQUEST: {
      return {
        ...state,
        currentDraggingSupplier: { ...payload }
      };
    }
    case HIERARCHY.SEARCH_SUPPLIERS.REQUEST: {
      return {
        ...state,
        isLoadingSearchResults: true
      };
    }
    case HIERARCHY.SEARCH_SUPPLIERS.SUCCESS: {
      return {
        ...state,
        isLoadingSearchResults: false,
        leftSupplierList: [...payload]
      };
    }
    case HIERARCHY.SEARCH_SUPPLIERS.FAILURE: {
      return {
        ...state,
        isLoadingSearchResults: false
      };
    }
    case HIERARCHY.SET_UPDATE_HIERARCHICAL_STRUCTURE.REQUEST: {
      const existingLeftSupplier = _.find(state.leftSupplierList, { childSuvc: payload });
      if (existingLeftSupplier) {
        return {
          ...state,
          leftSupplierList: [...state.leftSupplierList.filter(({ childSuvc }) => childSuvc !== payload)]
        };
      } else {
        const updatedNodes = handleRemoval([...state.hierarchyStructure.nodes], payload);
        return {
          ...state,
          hierarchyStructure: {
            ...state.hierarchyStructure,
            nodes: updatedNodes
          },
          currentDraggingSupplier: {}
        };
      }
    }
    case HIERARCHY.ADD_EMPTY_SINGLE_HIERARCHY_CONTAINER: {
      const { position, childSuvc, corporateSuvc } = payload;
      if (corporateSuvc) {
        const emptySupplier = createEmptySupplier();
        return {
          ...state,
          hierarchyStructure: {
            ...state.hierarchyStructure,
            nodes: [{ ...emptySupplier }]
          }
        };
      } else {
        const updatedNodes = handleAddingEmptyContainer(
          [...state.hierarchyStructure.nodes],
          position,
          childSuvc,
          state.hierarchyStructure
        );
        return {
          ...state,
          hierarchyStructure: {
            ...state.hierarchyStructure,
            nodes: updatedNodes
          }
        };
      }
    }
    case HIERARCHY.CHANGE_HIERARCHY_BUTTON_SELECT.REQUEST: {
      let updatedHierarchyAndLeftSuppliers = {};
      if (payload.isCreatingNewHierarchy) {
        updatedHierarchyAndLeftSuppliers = putAllNodesToAList(state.hierarchyStructure, state.currentStructure);
      } else {
        updatedHierarchyAndLeftSuppliers = {
          hierarchyStructure: state.currentStructure
          // leftSupplierList: []
        };
      }
      return {
        ...state,
        ...payload,
        ...updatedHierarchyAndLeftSuppliers
      };
    }
    case HIERARCHY.DROP_TO_EMPTY_SUPPLIER_CONTAINER.REQUEST: {
      const { replacingchildSuvc, dropingData } = payload;
      const updatedNodes = handleDropingSuppliersToEmptyContainer(
        [...state.hierarchyStructure.nodes],
        replacingchildSuvc,
        dropingData,
        state.hierarchyStructure.corporateSuvc
      );
      return {
        ...state,
        hierarchyStructure: {
          ...state.hierarchyStructure,
          nodes: updatedNodes
        }
      };
    }
    case HIERARCHY.LOAD_INITIAL_SUPPLIER.REQUEST: {
      return {
        ...state,
        isFetchingHierarchy: true
      };
    }
    case HIERARCHY.LOAD_INITIAL_SUPPLIER.SUCCESS: {
      return {
        ...state,
        hierarchyStructure: {
          ...payload
        },
        currentStructure: {
          ...payload
        },
        isFetchingHierarchy: false
      };
    }
    case HIERARCHY.LOAD_INITIAL_SUPPLIER.FAILURE: {
      const {
        hierarchy: { vendors, ...restHierarchyData }
      } = initialState;
      return {
        ...state,
        ...restHierarchyData
      };
    }
    case HIERARCHY.DELETE_EXISTING_SUPPLIER.REQUEST: {
      return {
        ...state,
        currentDeletingSupplier: { ...payload }
      };
    }
    case HIERARCHY.DELETE_EXISTING_SUPPLIER.FAILURE: {
      return {
        ...state,
        currentDeletingSupplier: {},
        isDeleteRequestSubmitted: false
      };
    }
    case HIERARCHY.DELETE_EXISTING_SUPPLIER.SUCCESS: {
      const { actionType: type, ...rest } = payload;
      const updatedNodes = deleteExistingSubsidiary(
        state.hierarchyStructure.nodes,
        rest,
        state.currentDeletingSupplier.childSuvc
      );
      return {
        ...state,
        hierarchyStructure: {
          ...state.hierarchyStructure,
          nodes: [...updatedNodes]
        },
        isDeleteRequestSubmitted: true
      };
    }
    case HIERARCHY.DELETE_EMPTY_SUPPLIER.REQUEST: {
      const updatedNodes = handleRemoval([...state.hierarchyStructure.nodes], payload);
      return {
        ...state,
        hierarchyStructure: {
          ...state.hierarchyStructure,
          nodes: [...updatedNodes]
        }
      };
    }
    case HIERARCHY.DISCARD_SUPPLIER_DELETION.REQUEST: {
      const updatedNodes = discardSupplierDeletion([...state.hierarchyStructure.nodes], payload);
      return {
        ...state,
        hierarchyStructure: {
          ...state.hierarchyStructure,
          nodes: [...updatedNodes]
        }
      };
    }
    case HIERARCHY.SUBMIT_CHANGES.REQUEST: {
      return {
        ...state,
        isSubmittingChanges: true
      };
    }
    case HIERARCHY.SUBMIT_CHANGES.SUCCESS: {
      return {
        ...state,
        hierarchyStructure: {
          ...state.hierarchyStructure,
          ...payload
        },
        currentStructure: { ...payload },
        isSubmittingChanges: false,
        isChangingCurrentHierarchy: false,
        isCreatingNewHierarchy: false
      };
    }
    case HIERARCHY.SUBMIT_CHANGES.FAILURE: {
      return {
        ...state,
        isSubmittingChanges: false
      };
    }
    case HIERARCHY.ALTER_NAME_LIST_DISPLAY.REQUEST: {
      return {
        ...state,
        ...payload
      };
    }
    case HIERARCHY.SEE_MORE_PAGE_CHANGE.REQUEST: {
      return {
        ...state,
        currentSeeMorePageNumber: state.currentSeeMorePageNumber + 1
      };
    }
    case HIERARCHY.UPDATE_FIELDS.REQUEST: {
      return {
        ...state,
        ...payload
      };
    }
    case HIERARCHY.GET_VENDORS.SUCCESS: {
      return {
        ...state,
        vendors: [...payload]
      };
    }
    default:
      return state;
  }
};

createReducer(HIERARCHY, initialState.hierarchy);

export default hierarchy;
