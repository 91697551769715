import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Hierarchy from './Components/Hierarchy';
import AppLogo from './styles/images/app-logos/item-mgmt.svg';
import HeaderTab from './Components/Templates/HeaderTab';
import styles from './styles/HierarchyApp.scss';
import { UserActions } from './Action/Actions';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = '/ui/hierarchy' + AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

const HierarchyRoutes = ({ username }) => {
  const dispatch = useDispatch();

  const loadUserDetails = () => {
    dispatch(UserActions.getUserDetails({ username }));
  };

  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    loadUserDetails();
  }, []);

  return (
    <div className="wrapper hierarchy-wrapper">
      <HeaderTab />
      <BrowserRouter>
        <Switch>
          <Route path="/suite/hierarchy" component={() => <Hierarchy />} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default HierarchyRoutes;
