import { HierarchyActionTypes } from '../ActionTypes';

const addSearchHierachy = data => {
  return {
    type: HierarchyActionTypes.ADD_SEARCH_HIERARCHY.REQUEST,
    payload: data
  };
};

const setCurrentDraggingSupplier = data => {
  return {
    type: HierarchyActionTypes.SET_DRAGGING_SUPPLIER.REQUEST,
    payload: data
  };
};

const updateHierarchicalStructure = data => {
  return {
    type: HierarchyActionTypes.SET_UPDATE_HIERARCHICAL_STRUCTURE.REQUEST,
    payload: data
  };
};

const addEmptyDropableContainer = data => {
  return {
    type: HierarchyActionTypes.ADD_EMPTY_SINGLE_HIERARCHY_CONTAINER,
    payload: data
  };
};

const searchSuppliers = payload => {
  return {
    type: HierarchyActionTypes.SEARCH_SUPPLIERS.REQUEST,
    payload
  };
};

const handleHierarchyChangeButtonsClick = payload => {
  return {
    type: HierarchyActionTypes.CHANGE_HIERARCHY_BUTTON_SELECT.REQUEST,
    payload
  };
};

const handleDropToEmptyContainer = payload => {
  return {
    type: HierarchyActionTypes.DROP_TO_EMPTY_SUPPLIER_CONTAINER.REQUEST,
    payload
  };
};

const loadInitialSupplierData = payload => {
  return {
    type: HierarchyActionTypes.LOAD_INITIAL_SUPPLIER.REQUEST,
    payload
  };
};

const startDeletingSupplierFromHierarchyStructure = payload => {
  return {
    type: HierarchyActionTypes.DELETE_EXISTING_SUPPLIER.REQUEST,
    payload
  };
};

const supplierDeleteReject = () => {
  return {
    type: HierarchyActionTypes.DELETE_EXISTING_SUPPLIER.FAILURE
  };
};

const supplierDeleteSubmit = payload => {
  return {
    type: HierarchyActionTypes.DELETE_EXISTING_SUPPLIER.SUCCESS,
    payload
  };
};

const deleteEmptySupplier = payload => {
  return {
    type: HierarchyActionTypes.DELETE_EMPTY_SUPPLIER.REQUEST,
    payload
  };
};

const discardDeletion = payload => {
  return {
    type: HierarchyActionTypes.DISCARD_SUPPLIER_DELETION.REQUEST,
    payload
  };
};

const submitHierarchyChanges = payload => {
  return {
    type: HierarchyActionTypes.SUBMIT_CHANGES.REQUEST,
    payload
  };
};

const alterHierarchyNameListDisplay = payload => {
  return {
    type: HierarchyActionTypes.ALTER_NAME_LIST_DISPLAY.REQUEST,
    payload
  };
};

const seeMoreSuppliers = () => {
  return {
    type: HierarchyActionTypes.SEE_MORE_PAGE_CHANGE.REQUEST
  };
};

const updateFields = payload => {
  return {
    type: HierarchyActionTypes.UPDATE_FIELDS.REQUEST,
    payload
  };
};

const getSuppliersList = () => {
  return {
    type: HierarchyActionTypes.GET_VENDORS.REQUEST
  };
};

export {
  addSearchHierachy,
  setCurrentDraggingSupplier,
  updateHierarchicalStructure,
  addEmptyDropableContainer,
  searchSuppliers,
  handleHierarchyChangeButtonsClick,
  handleDropToEmptyContainer,
  loadInitialSupplierData,
  startDeletingSupplierFromHierarchyStructure,
  supplierDeleteReject,
  supplierDeleteSubmit,
  deleteEmptySupplier,
  discardDeletion,
  submitHierarchyChanges,
  alterHierarchyNameListDisplay,
  seeMoreSuppliers,
  updateFields,
  getSuppliersList
};
