import { put } from 'redux-saga/effects';
import _ from 'lodash';
import { getRequest, postRequest } from '../../_http';
import { HierarchyActionTypes } from '../../Action/ActionTypes';

import { action } from '../../reduxHelpers';

import {
  updateKeyNamesInSearchResults,
  addXPositionToNodes,
  removeExistingSuppliersFromLeftList,
  getUniqueSuppliers,
  removePreviousSearchResults
} from '../../Util/util';
import { supplierSearchPageSize } from '../../Util/constants';

function* searchSuppliersAsync({ payload: { tableFilter, hierarchyStructure, leftSupplierList } }) {
  let fetchedResults = [];
  try {
    let page = 0;
    let requestPayload = {};
    if (tableFilter && tableFilter.length) {
      requestPayload = {
        ...requestPayload,
        tableFilter: [...tableFilter, { param: 'suffix', operator: '=', val: ['00'] }]
      };
    }
    let pageResults = [];
    let suppliers = [];
    do {
      try {
        requestPayload = { ...requestPayload, page, pageSize: supplierSearchPageSize };
        const { data: { data } = {} } = yield postRequest(`/global/suppliers`, requestPayload);
        pageResults = data;
        fetchedResults = [...data];
        suppliers = [...suppliers, ..._.uniqBy(data, 'suvc')];
      } catch (error) {
        pageResults = [];
      }
      page++;
    } while (pageResults.length === supplierSearchPageSize);
    const removedDuplicates = [
      ...removeExistingSuppliersFromLeftList(
        [
          ...getUniqueSuppliers([
            ...updateKeyNamesInSearchResults(suppliers, true),
            ...removePreviousSearchResults(leftSupplierList)
          ])
        ],
        hierarchyStructure
      )
    ];
    if (removedDuplicates.length === 0) throw new Error('No Results Found');
    else if (suppliers.length === 0) throw new Error('No Results Found');
    yield put({ type: HierarchyActionTypes.SEARCH_SUPPLIERS.SUCCESS, payload: [...removedDuplicates] });
  } catch (error) {
    yield put({ type: HierarchyActionTypes.SEARCH_SUPPLIERS.FAILURE });
    const suvcFilter = _.find(tableFilter, { param: 'suvc' }); // check if suvc filter is available in tableFilter
    let isExistingInLeftSide = false;
    if (suvcFilter && suvcFilter.val) {
      isExistingInLeftSide = _.find(leftSupplierList, { childSuvc: suvcFilter.val[0] }); // check if filtering suvc is already in left panel
    }
    if (!fetchedResults.length && isExistingInLeftSide) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Supplier already exists in search list',
          className: 'info',
          message: 'SEARCH EXISTS'
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: error.message,
          className: 'error',
          message: 'SEARCH FAILED'
        })
      );
    }
  }
}

function* getInitialSupplierAsync({ payload: { suvc } }) {
  try {
    if (suvc) {
      const { data } = yield getRequest(`/suppliers/${suvc}`);
      yield put({ type: HierarchyActionTypes.LOAD_INITIAL_SUPPLIER.SUCCESS, payload: data });
    } else {
      yield put({ type: HierarchyActionTypes.LOAD_INITIAL_SUPPLIER.FAILURE });
    }
  } catch (error) {
    yield put({ type: HierarchyActionTypes.LOAD_INITIAL_SUPPLIER.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FAILED LOADING SUPPLIER'
      })
    );
  }
}

function* submitSupplierHierarchyChangesAsync({ payload }) {
  try {
    const updatedPayload = { ...payload, nodes: addXPositionToNodes(payload.nodes) };
    const { data } = yield postRequest(`/suppliers`, updatedPayload);
    yield put({ type: HierarchyActionTypes.SUBMIT_CHANGES.SUCCESS, payload: data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'SUCCESSFULLY UPDATED',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: HierarchyActionTypes.SUBMIT_CHANGES.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FAILED SAVING/UPDATING HIERARCHY'
      })
    );
  }
}

function* getSupplierListAsync() {
  try {
    const { data } = yield getRequest(`/vendors`);
    yield put({ type: HierarchyActionTypes.GET_VENDORS.SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: HierarchyActionTypes.GET_VENDORS.FAILURE });
  }
}

export { searchSuppliersAsync, getInitialSupplierAsync, submitSupplierHierarchyChangesAsync, getSupplierListAsync };
