import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal, Radio, Select, Button, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { HierarchyActions } from '../../../Action/Actions';
import { dateTimeFormat, supplierDiscardPicklistValues } from '../../../Util/constants';

const { Option } = Select;

const DeleteModal = () => {
  const [isDeleteOptionEnabled, setDeleteOptionEnabled] = useState(false);
  const [feedbacks, setDeleteReason] = useState(null);
  const [comment, setComment] = useState(null);
  const dispatch = useDispatch();
  const currentDeletingSupplier = useSelector(state => _.get(state.hierarchy, 'currentDeletingSupplier', {}));
  const isDeleteRequestSubmitted = useSelector(state => _.get(state.hierarchy, 'isDeleteRequestSubmitted', false));

  const cancelSupplierDeletion = (isWithoutCleaningDeletedSupplier = false) => {
    setDeleteOptionEnabled(false);
    setDeleteReason(null);
    setComment(null);
    if (!isWithoutCleaningDeletedSupplier) dispatch(HierarchyActions.supplierDeleteReject());
  };

  const submitDeletion = () => {
    let updatedFeedback = feedbacks;
    if (feedbacks === 'Other') updatedFeedback = comment;
    dispatch(
      HierarchyActions.supplierDeleteSubmit({
        feedback: updatedFeedback,
        createdAt: moment(new Date()).format(dateTimeFormat),
        type: 'delete'
      })
    );
    cancelSupplierDeletion(true);
  };

  const getSubmitButtonDisableStatus = () =>
    !isDeleteOptionEnabled || !feedbacks || (feedbacks === 'Other' && !comment);

  const getDisabledStylesForSubmitButton = () => {
    if (getSubmitButtonDisableStatus()) return { backgroundColor: '#aeadad' };
    return {};
  };

  return (
    <Modal
      visible={currentDeletingSupplier.childSuvc && !isDeleteRequestSubmitted ? true : false}
      footer={null}
      centered
      closeIcon={<CloseOutlined style={{ color: '#FF0000' }} />}
      onCancel={() => cancelSupplierDeletion()}
    >
      <div style={{ display: 'grid', gridGap: '25px' }}>
        <p>Are you sure you would like to delete this subsidiary from your hierarchy?</p>
        <div>
          <Radio defaultChecked={false} checked={isDeleteOptionEnabled} onChange={() => setDeleteOptionEnabled(true)}>
            Yes
          </Radio>

          <Radio defaultChecked={false} checked={false} onChange={() => cancelSupplierDeletion()}>
            No
          </Radio>
        </div>
        <div style={{ display: 'grid' }}>
          <span>Reason (Why are you deleting this subsidiary from your hierarchy)*</span>
          <Select
            disabled={!isDeleteOptionEnabled}
            onChange={value => setDeleteReason(value)}
            value={feedbacks || 'Select one'}
          >
            {supplierDiscardPicklistValues.map(({ value }) => {
              return (
                <Option value={value} key={value}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </div>
        {feedbacks === 'Other' && (
          <div style={{ display: 'grid' }}>
            <span>Comment*</span>
            <Input placeholder="Enter your comment" value={comment} onChange={e => setComment(e.target.value)} />
          </div>
        )}
        <div>
          <Button
            onClick={() => {
              submitDeletion();
            }}
            disabled={getSubmitButtonDisableStatus()}
            style={{
              float: 'right',
              backgroundColor: '#008000',
              color: 'white',
              borderRadius: '10px',
              height: '25px',
              ...getDisabledStylesForSubmitButton()
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
