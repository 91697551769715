import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../Redux';
import rootSaga from '../Saga';

import initialState from './initialState';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
  reducer: rootReducer,
  preloadedState: initialState
});

sagaMiddleware.run(rootSaga);

export default store;
