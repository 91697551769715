const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const supplierDiscardPicklistValues = [
  { value: 'Divested' },
  { value: 'Bankrupt' },
  { value: 'No longer in business' },
  { value: 'Not a known subsidiary' },
  { value: 'Other' }
];

const tooltipConstants = {
  global:
    'Global Parent: a single company that has a controlling interest in another company or companies. A single company means that nothing goes above a global parent, only below.',
  subsidiary:
    'Subsidiary: Subsidiaries are related to one another by virtue of the fact that they share a common global parent. Subsidiaries can be sister companies (horizontal) or parent-child (vertical).'
};

const supplierSearchPageSize = 100;
const seeMoreSupplierListSize = 10;

export {
  dateTimeFormat,
  supplierDiscardPicklistValues,
  tooltipConstants,
  supplierSearchPageSize,
  seeMoreSupplierListSize
};
